<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">菜单列表</h3>
    </div>
    <div class="box">
      <div class="btnall">
        <el-button
          @click="dialogMenuShow = true"
          size="medium"
          type="primary"
          plain
          >添加 <i class="el-icon-plus"></i
        ></el-button>
      </div>
      <!-- <el-tree
        default-expand-all
        :data="list"
        :props="optionProps"
        @node-click="handleNodeClick"
      >
        <div class="bianji" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <div div class="btnBox">
            <el-tooltip effect="dark" content="点击编辑" placement="top-start">
              <el-button
                @click.stop="setmenu(data)"
                size="mini"
                type="primary"
                icon="el-icon-edit"
                circle
              ></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="点击删除" placement="top-start">
              <el-button
                @click.stop="delmenu(data)"
                size="mini"
                type="danger"
                icon="el-icon-delete"
                circle
              ></el-button
            ></el-tooltip>
           <el-tooltip effect="dark" content="查看菜单" placement="top-start">
              <el-button
                @click.stop="detalimenu(data)"
                size="mini"
                type="warning"
                icon="el-icon-star-off"
                circle
              ></el-button>
            </el-tooltip> -->
      <!-- </div>
        </div>
      </el-tree> -->
        <!-- default-expand-all -->
      <el-table
        row-key="id"
        :tree-props="tableprops"
        border
        :data="list"
        :row-style="{ height: '30px', padding: '0' }"
      >
        <!-- <el-table-column type="expand"> 123 </el-table-column> -->
        <el-table-column prop="id" label="id"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column prop="status" label="状态">
          <div slot-scope="scope">
            <el-tag
              v-if="scope.row.status === 1"
              size="mini"
              effect="dark"
              type="success"
              >已启用</el-tag
            >
            <el-tag v-else size="mini" effect="dark" type="danger"
              >已禁用</el-tag
            >
          </div>
        </el-table-column>
        <el-table-column label="操作">
          <div slot-scope="scope">
            <el-button
              @click="setmenu(scope.row)"
              size="mini"
              type="primary"
              icon="el-icon-edit"
              circle
            ></el-button>
            <el-button
              @click="deletemenu(scope.row)"
              size="mini"
              type="danger"
              icon="el-icon-delete"
              circle
            ></el-button>
          </div>
        </el-table-column>
      </el-table>
    </div>

    <!-- 添加编辑弹框 -->
    <el-dialog title="提示" :visible.sync="dialogMenuShow" width="30%">
      <el-form ref="form" :rules="rule" :model="form" label-width="80px">
        <el-form-item label="父级ID" prop="parent_id">
          <!-- <el-input disabled v-model="form.parent_id"></el-input> -->
          <el-cascader
            :placeholder="cascadertext"
            :props="optionProps"
            :options="list"
            @change="setclass"
            ref="cascader"
          ></el-cascader>
        </el-form-item>
        <el-form-item prop="name" label="菜单名称">
          <el-input size="small" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="应用名称" prop="app">
          <el-input v-model="form.app"></el-input>
        </el-form-item>
        <el-form-item label="控制器名" prop="controller">
          <el-input v-model="form.controller"></el-input>
        </el-form-item>
        <el-form-item label="操作名称" prop="action">
          <el-input v-model="form.action"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="icon">
          <el-input v-model="form.icon"></el-input>
        </el-form-item>
        <el-form-item label="前端路径" prop="index_path">
          <el-input v-model="form.index_path"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="list_order">
          <el-input v-model="form.list_order"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="list_order">
          <el-input type="textarea" v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item label="菜单类型" prop="type">
          <el-radio v-model="form.type" :label="1">显示</el-radio>
          <el-radio v-model="form.type" :label="2">不显示</el-radio>
        </el-form-item>
        <el-form-item label="是否隐藏" prop="status">
          <el-radio v-model="form.status" :label="1">展示</el-radio>
          <el-radio v-model="form.status" :label="0">不展示</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogMenuShow = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { menulist, addmenu, delmenu, menuDetail } from "@/api/irm.js";
export default {
  components: {},
  name: "",
  data() {
    return {
      cascadertext: "无",
      optionProps: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
      },
      tableprops: {
        children: "children",
      },
      dialogMenuShow: false,
      list: [],
      form: {
        parent_id: 0, // 上级菜单id
        name: "", // 名称
        app: "backend", // 应用名称
        controller: "", // 控制器名
        action: "", //操作名称
        icon: "",
        index_path: "", // 前端路径
        list_order: "", // 排序
        type: 1, // 菜单类型 显示 1 2
        status: 1, // 展示可选 1 0
        remark: ''
      },
      rule: {
        parent_id: [
          { required: true, message: "请选择父级id", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    dialogMenuShow: function (newdata, olddata) {
      if (newdata === false) {
        this.form.parent_id = 0;
        this.form.name = "";
        this.form.remark = "";
        this.form.app = "backend";
        this.form.controller = "";
        this.form.action = "";
        this.form.icon = "";
        this.form.index_path = "";
        this.form.list_order = "";
        this.form.type = 1;
        this.form.status = 1;
        delete this.form.id;
        this.cascadertext = "无";
      }
    },
  },
  methods: {
    async detalimenu(id) {
      const { data } = await menuDetail({ id });
      if (data.code != 200) return this.$message.error(data.data);
      this.cascadertext = data.data.name;
    },
    async submit() {
      const { data } = await addmenu(this.form);
      if (data.code != 200) return this.$message.error(data.data);
      this.dialogMenuShow = false;
      this.$message.success("添加成功");
      this.getlist();
    },
    setclass(id) {
      let nodesObj = this.$refs["cascader"].getCheckedNodes();
      console.log(nodesObj[0].data);
      this.form.parent_id = nodesObj[0].data.id;
    },
    setmenu(datas) {
      if (datas.parent_id != 0) {
        this.detalimenu(datas.parent_id);
      } else {
        this.cascadertext = "无";
      }
      console.log(datas);
      this.form.id = datas.id;
      this.form.remark = datas.remark;
      this.form.action = datas.action;
      this.form.parent_id = datas.parent_id;
      this.form.name = datas.name;
      this.form.app = datas.app;
      this.form.controller = datas.controller;
      this.form.icon = datas.icon;
      this.form.index_path = datas.index_path;
      this.form.list_order = datas.list_order;
      this.form.status = datas.status;
      this.form.type = datas.type;
      this.dialogMenuShow = true;
    },
    async deletemenu(item) {
      this.$confirm("您真的要删除吗?, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delmenu({ id: item.id });
          if (data.code != 200) return this.$message.error(data.data);
          this.getlist();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // setType(type) {},
    async getlist() {
      const { data } = await menulist();
      if (data.code != 200) return this.$message.error(data.data);
      this.list = data.data;
      console.log(this.list);
    },
  },
  created() {
    this.getlist();
  },
};
</script>

<style scoped lang='less'>
.box {
  .btnall {
    padding: 20px 10px;
    background-color: #f1f3f4;
    margin-bottom: 20px;
  }
}
/deep/.el-input__inner {
  width: 400px;
}
/deep/.el-table__expanded-cell {
  padding: 0;
}
.el-tree {
  font-size: 20px;
  padding: 10px;
  color: #111;
  border: 1px solid #ebeef5;
  /deep/.el-tree-node {
    border-bottom: 1px solid #ebeef5;
    &:last-child {
      border-bottom: 0;
    }
  }
  /deep/.el-tree-node__content {
    padding: 20px 0;
  }
  /deep/.el-tree-node__expand-icon {
    font-size: 20px;
  }
}
/deep/.bianji {
  display: flex;
  .btnBox {
    position: absolute;
    right: 150px;
  }
}
</style>